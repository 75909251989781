<template>
  <PageContainer>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="工程名称:" prop="name">
            <el-input
              v-model="searchModel.name"
              placeholder="请输入工程/施工单位/建设单位名称"
              clearable
              style="width: 280px">
            <!--<el-button slot="append" @click="busDialog = true">选择</el-button>-->
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{searchText}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">停止</el-button>
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          ref="xTable1"
          size="mini"
          id="measureTab"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :max-height="tableHeight"
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :align="allAlign"
          :data="tableData">
          <vxe-table-column field="companyName" title="企业名称" show-overflow="title" min-width="135" show-header-overflow="title"/>
          <!--                    <vxe-table-column field="ownerCode" title="楼号" show-overflow="title" min-width="60" show-header-overflow="title"/>-->
          <!--    <vxe-table-column field="matcCode" title="生产日期" show-overflow="title" min-width="60" show-header-overflow="title"/>
                    <vxe-table-column field="gradName" title="生产时间" show-overflow="title" min-width="120" show-header-overflow="title"/>-->
          <vxe-table-column field="projName" title="工程名称" show-overflow="title" min-width="120" show-header-overflow="title"/>
          <vxe-table-column field="constructionPoint" title="工程部位" min-width="60" show-overflow="title" show-header-overflow="title"/>
          <vxe-table-column field="other" title="其他技术要求" min-width="60" show-overflow="title" show-header-overflow="title"/>
          <vxe-table-column field="gradName" title="配合比号" show-overflow="title" min-width="120" show-header-overflow="title"/>
          <vxe-table-column field="licPlate" title="车牌号" show-overflow="title" min-width="120" show-header-overflow="title"/>
          <vxe-table-column field="discNum" title="盘数" min-width="60" show-overflow="title" show-header-overflow="title"/>
        <!-- <vxe-table-column field="contCapa" title="最大偏差" min-width="60" show-overflow="title" show-header-overflow="title"/>-->
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />

    </div>
  </PageContainer>
</template>

<script>
import { getMeasureList } from '@/apis/quality/concreteQuality'
export default {
  name: 'measure-manage',
  data () {
    return {
      searchModel: {
        name: ''
      },
      loading: false,
      searchText: '查询',
      tableData: [],
      tableHeight: 400,
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      }
    }
  },
  methods: {
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    submitSearch () {
      this.loading = true
      this.tableData = []
      // this.currentBusIndex = 0;
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    refreshData () {
      getMeasureList({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          this.tableData = data.rows
          this.tablePage.total = data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    }
  },
  mounted () {
    this.refreshData()
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
</style>
